<template>
  <b-tabs class="cus-tabs">
    <!-- <div class="d-flex justify-content-between align-items-center my-2">
      <h2>128 đơn hàng</h2>
      <div class="d-flex justify-content-end gap-1">
        <div class="d-flex align-items-center">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Tìm kiếm đơn hàng" />
          </b-input-group>
        </div>
        <div class="d-flex align-items-center">
          <v-select
            v-model="channel"
            label="name"
            :reduce="x => x.id"
            :options="channels"
            placeholder="Chọn sàn"
          />
        </div>
        <div class="d-flex align-items-center">
          <label class="white-space-nowrap">Ngày đặt hàng:
            <feather-icon
              v-b-tooltip.hover.top="'The maximum date range that may be specified with the Start date and End date fields is 15 days.'"
              icon="HelpCircleIcon"
              class="text-warning"
            />
          </label>
          <flat-pickr
            v-model="date_from_to"
            class="form-control mx-1"
            :config="configTimePicker"
            placeholder="Choose a date"
          />
          <b-button @click="handleFilter" class="white-space-nowrap">{{ $t('Filter') }}</b-button>
        </div>
      </div>
    </div> -->
    <b-tab
      v-for="(item, i) in tabList"
      :key="i"
      class="cus-tab"
    >
      <template #title>
        <span>{{ $t(item.name) }} ({{ tabList[i].items.length }})</span>
      </template>
      <div>
        <OrderTable
          :items="item.items"
          :fields="fields"
          :add_new_button="false"
          :filter_payment_button="true"
          :filter_status_button="false"
          :link_detail="'/order/shopee/edit'"
          @handleFilter="handleFilter"
          @handlerDelete="handlerDelete"
        />
      </div>
    </b-tab>
  </b-tabs>
</template>
<script>
/* eslint-disable array-callback-return */
import {
  BTabs,
  BTab,
  // BCard,
  // BCardHeader,
  VBTooltip,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import statusList from '@/mixins/status.json'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import OrderTable from '../../../components/custom-table/order-table.vue'

export default {
  components: {
    BTabs,
    BTab,
    // BCard,
    // BCardHeader,
    OrderTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'order_no', label: 'Order no', sortable: false },
        { key: 'created_at_stamp', label: 'Created at', sortable: true },
        // { key: 'address', label: 'Address', sortable: true },
        { key: 'fullname', label: 'Fullname', sortable: true },
        // { key: 'status_no', label: 'Status', sortable: false },
        { key: 'total_amount', label: 'Total', sortable: true },
        { key: 'payment_method', label: 'Payment Method', sortable: true },
        { key: 'order_status', label: 'Status', sortable: true },
        // { key: 'lang', label: 'Language', sortable: false },
        // { key: 'more', label: 'Tool', sortable: false },
        // { key: 'sort', label: 'Reorder', sortable: false },
      ],
      tabReturnRefund: [
        {
          id: 7,
          name: 'Return',
          count: 0,
          note: 'Trạng thái đang chuyển hàng trả lại',
          items: [],
        },

        {
          id: 8,
          name: 'Refund',
          count: 0,
          note: 'Trạng thái đang đợi chuyển tiền lại cho seller',
          items: [],
        },

        {
          id: 9,
          name: 'Refund processed',
          count: 0,
          note: 'Trạng thái đã trả tiền lại cho seller',
          items: [],
        },
      ],
      tabList: [
        {
          id: 0,
          name: 'All',
          count: 0,
          note: 'All',
          items: [],
        },
        {
          id: 1,
          name: 'Waiting for confirmation',
          count: 0,
          note: 'Đợi xác nhận',
          items: [],
        },
        {
          id: 2,
          name: 'Chờ lấy hàng',
          count: 0,
          note: 'Chờ lấy hàng',
          items: [],
        },
        {
          id: 3,
          name: 'Đang giao',
          count: 0,
          note: 'Đã xử lý',
          items: [],
        },
        {
          id: 4,
          name: 'Đã giao',
          count: 0,
          note: 'Đang giao hàng',
          items: [],
        },
        {
          id: 5,
          name: 'Đã huỷ',
          count: 0,
          note: 'Đã giao hàng xong, khách đã nhận được hàng',
          items: [],
        },
      ],
      orderProductList: [],
      date_from_to: null,
      sdate: null,
      edate: null,
      configTimePicker: {
        minDate: null,
        maxDate: null,
        mode: 'range',
        dateFormat: 'd/m/Y',
        defaultDate: [new Date().fp_incr(-15), new Date()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.sdate = start.toLocaleDateString('en-ZA')
            this.edate = end.toLocaleDateString('en-ZA')
          }
        },
      },
      channels: [
        {
          id: null,
          name: 'Tất cả các sàn',
        },
        {
          id: 1,
          name: 'Website',
        },
        {
          id: 2,
          name: 'Shopee',
        },
        {
          id: 3,
          name: 'Lazada',
        },
      ],
      channel: null,
    }
  },
  mounted() {
    const now = new Date()
    const pre = new Date()
    pre.setDate(pre.getDate() - 15)
    // eslint-disable-next-line prefer-destructuring
    this.sdate = pre.toISOString().split('T')[0]
    // eslint-disable-next-line prefer-destructuring
    this.edate = now.toISOString().split('T')[0]
    // this.sdate = '2021/06/15'
    // this.edate = '2021/06/30'
    this.loadSiteInfo()
  },
  methods: {
    async handlerSort(data) {
      const { item } = data
      const { method } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: item.item.id,
              position: Number(item.item.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/order/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/orders?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const mapData = []
            res.data.data.map(val => {
              mapData.push({
                ...val,
                created_at_utc: val.created_at,
                status: statusList.filter(x => x.id === val.status)[0].key,
                status_no: statusList.filter(x => x.id === val.status)[0].id,
                totalF: Number(val.total) + Number(val.shipping_price) - Number(val.coupon_discount),
              })
            })
            // ALl
            this.tabList[0].items = await this.getDataTranslation(mapData, true)
            this.tabList[0].count = mapData.length
            // Waiting for comfirmation
            this.tabList[1].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 'UNPAID'), true)
            this.tabList[1].count = mapData.filter(x => x.status_no === 'UNPAID').length
            // To process
            this.tabList[2].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 'READY_TO_SHIP'), true)
            this.tabList[2].count = mapData.filter(x => x.status_no === 'READY_TO_SHIP').length
            // Processed
            this.tabList[3].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 'TO_CONFIRM_RECEIVE'), true)
            this.tabList[3].count = mapData.filter(x => x.status_no === 'TO_CONFIRM_RECEIVE').length
            // Shipping
            this.tabList[4].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 4), true)
            this.tabList[4].count = mapData.filter(x => x.status_no === 4).length
            // Completed
            this.tabList[5].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 5), true)
            this.tabList[5].count = mapData.filter(x => x.status_no === 5).length
            // Cancel
            // this.tabList[6].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 6), true)
            // this.tabList[6].count = mapData.filter(x => x.status_no === 6).length
            // Return + Refund + Refund processed
            // this.tabReturnRefund[0].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 7), true)
            // this.tabReturnRefund[0].count = mapData.filter(x => x.status_no === 7).length
            // // Refund
            // this.tabReturnRefund[1].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 8), true)
            // this.tabReturnRefund[1].count = mapData.filter(x => x.status_no === 8).length
            // // Refund processed
            // this.tabReturnRefund[2].items = await this.getDataTranslation(mapData.filter(x => x.status_no === 9), true)
            // this.tabReturnRefund[2].count = mapData.filter(x => x.status_no === 9).length
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/order/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Trademark has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    handleFilter(data) {
      this.sdate = data.sdate
      this.edate = data.edate
      // this.$router.replace({ query: { sdate: this.sdate, edate: this.edate } })
      this.loadSiteInfo()
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.connected = true
              this.loadShopeeOrder(JSON.parse(localStorage.getItem('siteID')).id)
            } else if (this.$route.query.shop_id) {
              await this.handlerUpdate(this.$route.query.shop_id)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadShopeeOrder(siteID) {
      this.isShow = true
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_order_list?site_id=${siteID}&create_time_from=${this.sdate}&create_time_to=${this.edate}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // const mapData = []
            res.data.data.map(async val => {
              const resDetail = await Request.get(
                this.$http,
                `${process.env.VUE_APP_API_URL}/shopee/get_order_detail?ordersn_list[]=${val.ordersn}&site_id=${siteID}`,
              )
              if (resDetail.status === 200) {
                if (resDetail.data.status) {
                  this.syncTabs(0, resDetail.data.data[0])
                  if (resDetail.data.data[0].order_status === 'UNPAID') {
                    this.syncTabs(1, resDetail.data.data[0])
                  }
                  if (resDetail.data.data[0].order_status === 'READY_TO_SHIP') {
                    this.syncTabs(2, resDetail.data.data[0])
                  }
                  if (resDetail.data.data[0].order_status === 'TO_CONFIRM_RECEIVE') {
                    this.syncTabs(3, resDetail.data.data[0])
                  }
                  if (resDetail.data.data[0].order_status === 'COMPLETED') {
                    this.syncTabs(4, resDetail.data.data[0])
                  }
                  if (resDetail.data.data[0].order_status === 'CANCELLED') {
                    this.syncTabs(5, resDetail.data.data[0])
                  }
                }
              }
            })
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    syncTabs(id, data) {
      this.tabList[id].items.push({
        id: data.ordersn,
        order_no: data.ordersn,
        payment_status_text: data.order_status,
        payment_method: data.payment_method,
        shipping_status_text: data.shipping_carrier,
        total: data.total_amount,
        fullname: data.buyer_username,
        list: this.mapItem(data.items),
        cancel_reason: data.cancel_reason,
        cancel_by: data.cancel_by,
        created_at_stamp: data.create_time,
      })
    },
    mapItem(items) {
      const temp = []
      items.map(x => {
        temp.push({
          name_product: x.item_name,
          avatar_product: x.item_avatar ? x.item_avatar : this.avatarDefault,
          price: x.variation_original_price,
          quantity: x.variation_quantity_purchased,
        })
      })
      return temp
    },
  },
}
</script>
<style lang="sass">
.cus-tabs
  .nav.nav-tabs
    // border-bottom: .5px solid #ccc
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)
    padding: 8px
    width: fit-content
    // margin: auto
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.white-space-nowrap {
  white-space: nowrap;
}
.gap-1 {
  gap: 1.5rem;
}
</style>
